import React, { useReducer } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './css/styles.css';
import Lang from './services/Lang';
import Error from './services/Error';

/* State */
import { INITIAL_GLOBAL_STATE, globalStateReducer } from './state/GlobalState';
import { AppContext } from './state/AppContextProvider';
import Header from './components/global/Header';
import Footer from './components/global/Footer';
import { LoadPointOfSale } from './components/global/LoadPointOfSale';
import Home from './components/home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PartnersPage from './components/partner/PartnersPage';
import CampaignPage from './components/campaign/CampaignPage';
import PageFooter from './components/global/PageFooter';

const App: React.FC = () => {
  const [state, dispatch] = useReducer(
    globalStateReducer,
    INITIAL_GLOBAL_STATE
  );

  return (
    <HelmetProvider>
      <AppContext.Provider value={{ state, dispatch }}>
        <Helmet>
          <meta charSet="utf-8" />
          <html lang="fr" />
        </Helmet>
        <Error>
          <Lang>
            <LoadPointOfSale />
            <BrowserRouter>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/campaign/:campaignId" element={<CampaignPage />} />
                <Route path="/partners/:partnerId" element={<PartnersPage />} />
                <Route path="/mentions-legales" element={<PageFooter color={state.pointOfSale?.sites[0]?.siteColorMain} text={state.pointOfSale?.sites[0]?.siteRegulation}/>} />
                <Route path="/politique-de-confidentialite" element={<PageFooter color={state.pointOfSale?.sites[0]?.siteColorMain} text={state.pointOfSale?.sites[0]?.sitePdc}/>} />
              </Routes>
              <Footer />
            </BrowserRouter>
          </Lang>
        </Error>
      </AppContext.Provider>
    </HelmetProvider>
  );
}

export default App;